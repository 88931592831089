<template>
  <div class="mc_embed_signup">
    <form action="https://gmail.us11.list-manage.com/subscribe/post?u=af3a9e6aa73933d02a9523931&amp;id=0bfa9da9f6"
          method="post" name="mc-embedded-subscribe-form" class="validate"
          target="_blank" novalidate>
      <div>
        <label v-if="label" class="mb-2 d-block">{{ label }}</label>
        <div class="mc-field-group">
          <input type="email" value="" name="EMAIL" class="required mc-email" placeholder="Notify me when it's released">
          <input type="submit" value="Notify Me" name="subscribe" class="button">
        </div>
        <div class="clear foot">
          <div class="response" style="display:none"></div>
          <div class="response" style="display:none"></div>
        </div>
        <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
        <div style="position: absolute; top: -50000px;" aria-hidden="true"><input type="text"
                                                                                  name="b_af3a9e6aa73933d02a9523931_0bfa9da9f6"
                                                                                  tabindex="-1" value=""></div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "MailchimpForm",
  props: {
    label: {
      type: String
    }
  }
}
</script>

<style scoped>
.mc_embed_signup {
  max-width: 600px;
}

.mc-field-group {
  display: flex;
  align-items: center;
  flex-shrink: 1;
}

.mc-email {
  background: #EFF7F6;
  height: 40px;
  padding: 10px 12px;
  max-width: 315px;
  flex-grow: 1;
  margin-right: 19px;
}

.button {
  background: var(--v-primary-base);
  border-radius: 80px;
  height: 40px;
  padding: 8px 24px;
  color: var(--v-darkFont-base);
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

</style>