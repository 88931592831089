<template>
  <v-app>
    <v-main>
      <v-container class="pa-0" fluid>
        <router-view></router-view>
        <footer class="pb-5">
          <div class="max-width d-flex flex-column align-center">
            <div class="content-width px-4 px-sm-8 d-flex flex-column align-center">
              <img src="./assets/logo_2x.png" height="32" alt="Evolve UI" class="evolve-logo mt-10 mb-16"/>
              <div class="d-flex flex-column">
                <span>
                  {{ new Date().getFullYear() }} &copy; Evolve Game Tools GmbH, All rights reserved.
                </span>
                <router-link to="/privacy">Impressum / Datenschutzerklärung</router-link>
              </div>
            </div>
          </div>
        </footer>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import "@fontsource/lato"
import "@fontsource/exo"

export default {
  name: 'App',
  data() {
    return {
    };
  }
}
</script>

<style scoped>
#app {
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #ffffff;
  background: var(--v-background-base);
}

footer {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  text-align: center;
}

footer span, footer a {
  color: var(--v-lightBackground-base);
  opacity: 0.4;
}

.max-width {
  max-width: 1200px;
  width: 100%;
}

.content-width {
  max-width: 1024px;
  width: 100%;
}

</style>
