import Home from '@/components/Home';
import Privacy from "@/components/Privacy";

const routes = [
  {
    component: Home,
    name: 'home',
    path: '/'
  },
  {
    component: Privacy,
    name: 'privacy',
    path: '/privacy'
  }
];


export default routes;
