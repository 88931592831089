<template>
  <v-container class="home pa-0" fluid>
    <div class="center-wrapper dark-bg hero-background max-width pa-4 pt-sm-16">
      <div class="content-width px-4 px-sm-8 navigation d-flex align-center justify-space-between">

        <img src="../assets/logo_2x.png" height="32" alt="Evolve UI"/>
        <div class="navigation-items d-none d-sm-flex">
          <a @click="scrollTo(item)" v-for="(item, index) in items"
             :key="'menu_' + index">{{ item.label }}</a>
        </div>
        <v-app-bar-nav-icon @click="drawer = true"
                            class="d-flex d-sm-none burger-icon"
        ></v-app-bar-nav-icon>

        <v-navigation-drawer
            v-model="drawer"
            absolute
            width="100%"
            temporary
        >
          <div class="d-flex justify-space-between pa-4">
            <img src="../assets/logo_2x.png" height="32" alt="Evolve UI"/>
            <v-app-bar-nav-icon @click="drawer = false"
                                class="d-flex d-sm-none burger-icon"
            ></v-app-bar-nav-icon>
          </div>
          <dl class="burger-list">
            <dd v-for="(item, index) in items" :key="'smallmenu_' + index" @click="scrollTo(item)">
              <span>{{ item.label }}</span>
            </dd>
          </dl>
        </v-navigation-drawer>
      </div>
      <div class="pa-4 pa-sm-8 pt-8 pt-sm-16 content-width">
        <h1 class="evolve-title">A UI Engine for Unity that does all the heavy lifting.</h1>
        <p class="copy">Evolve was created based on a decade of high performance UI experience
          to enable developers and designers to quickly build professional grade UI that scales with your game. Compose complex UI from simple building blocks using a declarative templating and style language that is perfectly interoperable with C#.
          </p>
        <MailchimpForm label="Evolve is currently in closed alpha" class="big-mb"/>
      </div>
    </div>
    <div class="center-wrapper dark-bg max-width">
      <v-row class="pa-4 pa-sm-8 content-width">
        <v-col class="col-12 col-sm-4 d-flex flex-column">
          <img :src="require('../assets/HandEye.svg')" height="68" class="align-self-sm-start mb-10"/>
          <h4>Developer first workflow optimized for iteration</h4>
          <p>
            Supercharge your UI development productivity. A good UI changes and matures dramatically overtime.<br/>
            Evolve can keep up with you.
          </p>
        </v-col>
        <v-col class="col-12 col-sm-4 d-flex flex-column">
          <img :src="require('../assets/CodesandboxLogo.svg')" height="68" class="align-self-sm-start mb-10"/>
          <h4>Compose complex UI from simple building blocks</h4>
          <p>
            Snap pieces of UI together like lego blocks. Databinding makes it easy to hook up data into your UI in seconds. It's easy to
            read, write, and iterate.
          </p>
        </v-col>
        <v-col class="col-12 col-sm-4 d-flex flex-column">
          <img :src="require('../assets/Lightning.svg')" height="68" class="align-self-sm-start mb-10"/>
          <!-- <h4>Compiles into super fast, allocation free C#</h4> -->
          <h4>Write UI that runs like lightning</h4>
          <p>
            Evolve is centered around a novel compiler that natively understands the UI flow and compiles UI templates and styles into 
            lightning fast code specifically for your project.
          </p>
        </v-col>
      </v-row>
    </div>
    <div class="center-wrapper light-bg" id="code">
      <v-row class="pa-4 pa-sm-8 content-width">
        <v-col class="col-12 col-sm-4">
          <h4>Seamless C# integration</h4>
          <p>
            Any logic you could have written in C# will work seamlessly in an Evolve template. 
            All of your public classes, structs, and enums can be used as well as any public field, method, property or event on those types. 
            No boilerplate nessessary, it just works. 
            <br/><br/>
          </p>
          <h4>Predictable Flow</h4>
          <p>
            Templates are compiled into functions that run every frame of your game loop from top to bottom just like C# would.
            This keeps data flow predictable and allows us to use declarative data binding instead of littering our code with event
            handlers.          
             <br/><br/>
          </p>
          <h4>Composable UI Elements</h4>
          <p>
            Evolve is built on the principal of composition. You can reuse and parameterize UI elements just like you would function calls in C#. 
            <br/><br/>
          </p>
        </v-col>
        <v-col class="col-12 col-sm-8">
          <CodeDisplay :code="examples[0]">
            <img src="../assets/examples/example1-output.png" class="stretch-image" height="495" width="532"/>
          </CodeDisplay>
        </v-col>
      </v-row>
      <!-- <v-row class="pa-4 pa-sm-8 content-width">
        <v-col class="col-12 col-sm-4">
          <h4>Composable UI Elements</h4>
          <p>
            Neplavis viföv. Elektrotion miv för plalöt innan tvåkönsnorm duviskapet. Tetravill pekönt, dekajän.
          </p>
        </v-col>
        <v-col class="col-12 col-sm-8">
          <CodeDisplay :code="examples[1]"/>
        </v-col>
      </v-row> -->
      <!-- <v-row class="pa-4 pa-sm-8 content-width">
        <v-col class="col-12 col-sm-4">
          <h4>All the control you need</h4>
          <p>
            All the usual control flow constructs are available in Evolve templates. if/else if/else, switch, foreach loops, ternary statements are all usable 
          </p>
        </v-col>
        <v-col class="col-12 col-sm-8">
          <CodeDisplay :code="examples[2]"/>
        </v-col>
      </v-row> -->
    </div>

    <div class="center-wrapper dark-bg max-width" id="how">
      <v-row class="px-4 px-sm-8 pt-16 content-width">
        <v-col class="col-12">
          <h1>Under The Hood</h1>
        </v-col>
      </v-row>

      <v-row class="pa-4 pa-sm-8 content-width">
        <v-col class="col-12 col-sm-6">
          <h3>Compilation loop</h3>
          <p>
            Evolve takes in assets like textures, sounds, shaders, templates, and style files and emits either
            hot-reloadable MSIL assembly code or fully debuggable C#.
          </p>
          <img src="../assets/diagram-comp.png" class="stretch-image flex-shrink-1 flex-grow-0 diagram"/>
        </v-col>
        <v-col class="col-12 col-sm-6 d-flex flex-column">
          <h3>The Life Cycle</h3>
          <p class="mr-sm-12">
            The runtime is split into two parts: a single threaded user update loop and a fully multithreaded
            asynchronous internal update.
          </p>
          <img src="../assets/diagram-life.png" class="stretch-image flex-shrink-1 flex-grow-0 diagram"/>
        </v-col>
      </v-row>
    </div>
    <div class="pattern-background center-wrapper" id="features">
      <div class="center-wrapper max-width">
        <v-row class="px-4 px-sm-8 pt-16 content-width">
          <v-col>
            <h1>Take Control Of Your UI.</h1>
          </v-col>
        </v-row>
        <v-row class="px-4 px-sm-8 pt-16 mb-16 content-width">
          <v-col class="col-12 col-sm-6">
            <div class="mr-8">
              <h4>Less clicky clicky, more typey typey</h4>
              <p>
                Design tools and drag and drop wysiwyg editors are wonderful for prototyping but when it comes to
                producing fast and maintainable UI, the developer knows best. Evolve puts the developer in full control.
              </p>
            </div>
          </v-col>
          <v-col class="col-12 col-sm-6">
            <div class="mr-8">
              <h4>Sophisticated, approachable layout engine</h4>
              <p>
                Evolve comes loaded with an extremely versatile, yet super simple layout engine that can handle many
                thousands
                of elements per frame. It uses a formal box model where any axis can stretch.
              </p>
            </div>
          </v-col>
          <v-col class="col-12 col-sm-6">
            <div class="mr-8">
              <h4>UI with <i>style</i></h4>
              <p>
                Evolve exposes over 150 properties that are settable via style sheets. You can even create your own
                properties and set them using variables. Visual
                tweaks and layout declarations are extremely simple to make.
              </p>
            </div>
          </v-col>
          <v-col class="col-12 col-sm-6">
            <div class="mr-8">
              <h4>Animate anything</h4>
              <p>
                Game UI aims to delight, and it's hard to get more delightful than a slick animation. Evolve includes
                a full animation system that handles spring physics, tweening, animation curves, beziers, step
                functions, and more.
              </p>
            </div>
          </v-col>
          <v-col class="col-12 col-sm-6">
            <div class="mr-8">
              <h4>Draw like Dalí</h4>
              <p>
                Rendering is fully SDF based and is able to push out an insane number of UI quads per frame. If you need
                something special it's easy to drop in your own painting logic. Shapes, particles, gradients, post
                processing and more all come standard.
              </p>
            </div>
          </v-col>
          <v-col class="col-12 col-sm-6">
            <div class="mr-8">
              <h4>Need for Speed</h4>
              <p>
                Evolve is optimized for dynamic games and can handle many thousands of UI elements every frame. The
                internals run on the Unity Job system and heavily leverage SIMD.<br/> No garbage, no GameObjects, no
                performance stalls.
              </p>
            </div>
          </v-col>
          <!-- <v-col class="col-12 col-sm-6">
            <div class="mr-8">
              <h4>A UI Engine that works with you</h4>
              <p>
                Every single decision in Evolve is centered around exposing huge functionality in a simple and
                understandable manner. In the rare event that the engine can't do what you need, it makes it easy to
                implement yourself.
              </p>
            </div>
          </v-col> -->
        </v-row>

        <v-row class="content-width flex-column align-center">
          <v-col class="col-12 col-sm-8">
            <h3>Leave an email to get notified about updates and milestone releases.</h3>
          </v-col>
        </v-row>
        <v-row id="signup" class="content-width flex-column align-center">
          <v-col class="col-12 col-sm-8 col-md-6">
            <MailchimpForm class="flex-grow-1"/>
          </v-col>
        </v-row>
        <v-row class="my-16">
          <v-col>
            <h3>
              <img src="../assets/discord.svg" width="33" height="33" class="discord" />
              <a href="https://discord.gg/aqYdNWP385" target="_blank" rel="nofollow noopener" class="discord mb-4">Join our Discord</a>
            </h3>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>

import "@fontsource/lato"
import "@fontsource/exo"
import CodeDisplay from "@/components/CodeDisplay"
import MailchimpForm from "@/components/MailchimpForm"
import example1cs from 'raw-loader!../assets/examples/example1.cs';
import example1style from 'raw-loader!../assets/examples/example1.style';
import example1template from 'raw-loader!../assets/examples/example1.ui';
import example2cs from 'raw-loader!../assets/examples/example2.cs';
import example2style from 'raw-loader!../assets/examples/example2.style';
import example2template from 'raw-loader!../assets/examples/example2.ui';
import example3cs from 'raw-loader!../assets/examples/example3.cs';
import example3style from 'raw-loader!../assets/examples/example3.style';
import example3template from 'raw-loader!../assets/examples/example3.ui';

export default {
  name: "Home",
  components: {
    CodeDisplay,
    MailchimpForm
  },
  data() {
    return {
      drawer: false,
      tab: null,
      items: [
        {
          href: 'code',
          label: 'Code'
        },
        {
          href: 'how',
          label: 'How it works'
        },
        {
          href: 'features',
          label: 'Features',
        },
        {
          href: 'signup',
          label: 'Sign up',
        }
      ],
      examples: [
        {
          csharp: example1cs,
          ui: example1template,
          style: example1style
        },
        {
          csharp: example2cs,
          ui: example2template,
          style: example2style
        },
        {
          csharp: example3cs,
          ui: example3template,
          style: example3style
        }
      ]
    }
  },
  methods: {
    scrollTo(item) {
      this.drawer = false;
      document.getElementById(item.href).scrollIntoView();
    }
  }
}
</script>

<style scoped>

.dark-bg {
  background: var(--v-background-base);
}

.light-bg {
  background: var(--v-lightBackground-base);
}

.big-mb {
  margin-bottom: 200px;
}

.hero-background {
  background: linear-gradient(180deg, rgba(28, 32, 33, 0) 81.02%, #1C2021 100%), url('../assets/background.png');
  background-size: cover;
}

.pattern-background {
  width: 100%;
  padding: 16px;
  background: url('../assets/tiled-background.png');
  background-size: cover;
  background-blend-mode: soft-light;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center-wrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
}

.navigation a {
  color: var(--v-lightBackground-base);
  margin-left: 16px;
  text-decoration: none;
}

.navigation a:hover {
  text-decoration: underline;
  text-decoration-color: var(--v-primary-base);
}

.max-width {
  max-width: 1200px;
  width: 100%;
}

.content-width {
  max-width: 1024px;
  width: 100%;
}

.navigation-items {
  color: white;
}

.navigation-items > span {
  display: inline-block;
  margin-right: 20px;
  text-transform: uppercase;
}

.navigation .burger-icon {
  color: var(--v-primary-base);
}

.v-navigation-drawer {
  background: var(--v-background-base);
}

.burger-list {
  display: flex;
  flex-direction: column;

}
.burger-list dd {

}

.burger-list dd {
  position: relative;
  color: var(--v-lightBackground-base);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  padding: 10px 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.burger-list dd:hover {
  background: #EFF7F619;
}

.burger-list dd:active {
  color: var(--v-primary-base);
}

.burger-list dd:not(:first-child) span::before {
  position: absolute;
  top: 0;
  left: 0;
  content: " ";
  background: url("../assets/divider.png");
  background-size: contain;
  width: 100%;
  height: 2px;
}

p.copy {
  margin-bottom: 85px;
}

.diagram {
  max-width: 351px;
}

.stretch-image {
  width: 100%;
  height: auto;
}

h1, h2, h3, h4 {
  font-family: "Exo", sans-serif;
}

h1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
}

h1.evolve-title {
  font-weight: 300;
  font-size: 40px;
  line-height: 53px;
  text-decoration: underline;
  text-decoration-color: var(--v-primary-base);
  text-underline-offset: 1.15px;
  max-width: 822px;
  margin-bottom: 19px;
}

@media only screen and (min-width: 599.98px) {

  h1 {
    font-size: 48px;
    line-height: 64px;
  }

  h1.evolve-title {
    font-size: 64px;
    line-height: 85px;
  }
}

h2 {
  font-size: 40px;
  line-height: 53px;
}

h3 {
  font-size: 32px;
  line-height: 43px;
  color: var(--v-accent-base);
}

h4 {
  font-weight: 700;
  font-size: 20px !important;
  line-height: 27px;
  color: var(--v-accent-base);
}

.pattern-background h3 {
  color: var(--v-lightBackground-base);
  margin-bottom: 14px;
  max-width: 573px;
  text-align: center;
}

.pattern-background h4 {
  color: var(--v-lightBackground-base);
  margin-bottom: 12px;
}

p {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--v-background-base);
}

.pattern-background p,
.dark-bg p {
  color: var(--v-lightBackground-base);
}

a.discord {
  color: var(--v-lightBackground-base);
}
a.discord:hover {
  color: var(--v-lightBackground-darken1);
}

img.discord {
  vertical-align: sub;
  margin-right: 8px;
}

</style>
