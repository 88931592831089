import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const vuetifyOpts = {
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        /* Eerie Black */
        background: '#1C2021',
        lightBackground: '#EFF7F6',
        /* Jet */
        darkFont: '#363636',
        /* French Blue */
        primary: '#06D2FF',
        secondary: '#5F5F5F',
        /* French Rose */
        accent: '#F74A8D',
        info: '#1976d2',
        warning: '#ffa000',
        error: '#d32f2f',
        success: '#388e3c'
      }
    }
  }
};

export default new Vuetify(vuetifyOpts);
