<template>
  <v-tabs class="code-tabs"
          background-color="background"
          color="lightBackground"
          hide-slider
          active-class="code-tab-active"
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
  >
    <v-tab class="code-tab">
      UI
    </v-tab>
    <v-tab class="code-tab">
      Style
    </v-tab>
    <v-tab class="code-tab">
      C#
    </v-tab>
    <v-tab class="code-tab ml-auto">
      <img src="../assets/o-icon.png" height="21" width="22" class="mr-2" /> Output
    </v-tab>
    <v-tab-item class="code-tab-item">
      <div class="code">
        <prism language="csharp">{{ code.ui }}</prism>
      </div>
    </v-tab-item>
    <v-tab-item class="code-tab-item">
      <div class="code">
        <prism language="css">{{ code.style }}</prism>
      </div>
    </v-tab-item>
    <v-tab-item class="code-tab-item">
      <div class="code">
        <prism language="csharp">{{ code.csharp }}</prism>
      </div>
    </v-tab-item>
    <v-tab-item class="code-tab-item">
      <div class="code d-flex justify-center">
        <slot/>
      </div>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import "prismjs"
import "prismjs/themes/prism-okaidia.css"
import "prismjs/components/prism-csharp"
import Prism from "vue-prism-component"

export default {
  name: "CodeDisplay",
  components: {
    Prism
  },
  props: {
    code: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.v-application .code-tabs .v-icon,
.v-application .code-tabs .v-tab.code-tab {
  color: var(--v-lightBackground-base);
}

.v-application .code-tabs >>> .v-icon {
  color: var(--v-lightBackground-base) !important;
}
.v-application .code-tabs >>> .v-icon--disabled {
  color: var(--v-background-base) !important;
}

.v-application .code-tabs .v-tab.code-tab.code-tab-active {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--v-accent-base);
  background-color: #292C33;
}

.code-tab:last-child {
  float: right;
}

:not(pre) > code[class*="language-"], pre[class*="language-"],
.code-tab-item {
  background-color: #292C33;
}

.v-application code {
  padding: 0;
}
</style>